<template>
  <div>
    <v-form ref="form2" class="multi-col-validation">
      <v-text-field

        class="mx-2"
        v-model="land_title_no"
        label="Land Title #"
        dense
        outlined
        :rules="rules.default_max_45_character_and_no_empty_rule"
      ></v-text-field>
      <v-text-field

        class="mx-2"
        v-model="land_area"
        label="Land Area"
        dense
        outlined
        :rules="rules.default_max_45_character_and_no_empty_rule"
      ></v-text-field>
      <v-text-field

        class="mx-2"
        v-model="location"
        label="Location"
        dense
        outlined
        :rules="rules.default_max_45_character_and_no_empty_rule"
      ></v-text-field>
      <v-text-field

        class="mx-2"
        v-model="land_procure"
        label="Land Procure"
        dense
        outlined
        :rules="rules.default_max_45_character_and_no_empty_rule"
      ></v-text-field>
      <v-text-field

        class="mx-2"
        v-model="land_value"
        label="Land Value"
        dense
        outlined
        :rules="rules.default_max_45_character_and_no_empty_rule"
        type="number"
        min="0"
      ></v-text-field>
      <v-select
        outlined
        class="mx-2"
        v-model="type_of_property"
        dense
        label="Type of Property"
        :items="['Agricultural','Commercial','Residential']"
        :rules="rules.combobox_rule"
      ></v-select>
      <v-text-field

        class="mx-2"
        v-model="pre_owner"
        label="Prev. Owner"
        dense
        outlined
        :rules="rules.default_max_45_character_and_no_empty_rule"
      ></v-text-field>
      <v-text-field
        class="mx-2"
        v-model="sell_to"
        label="Sell to"
        dense
        outlined
        :rules="rules.default_max_45_character_and_no_empty_rule"
      ></v-text-field>
      <v-col cols="12" v-show="alert">
        <v-alert
          color="warning"
          text
          class="mb-0"
        >
          <div class="d-flex align-start">
            <v-icon color="warning">
              {{ icons.mdiAlertOutline }}
            </v-icon>

            <div class="ms-3">
              <p class="text-base font-weight-medium mb-1">
                {{alert_message}}
              </p>
            </div>
          </div>
        </v-alert>
      </v-col>
      <v-col cols="12" md="12">
        <v-btn
          class="w-full"
          color="primary"
          @click="save_request"
          v-if="!saving_data"
        >
          Save Changes
        </v-btn>
        <v-progress-circular
          :size=50
          :width="5"
          color="primary"
          indeterminate
          v-else
        ></v-progress-circular>
      </v-col>
    </v-form>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mapActions, mapGetters} from "vuex";
  import {mdiCheck, mdiClose} from "@mdi/js";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      saving_data: false,
      alert: false,
      alert_message: '',

      pre_owner: '',
      land_value: '0',
      land_procure: '',
      location: '',
      land_area: '',
      land_title_no: '',
      sell_to: '',

      type_of_property: '',
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    data() {
      return initialState()
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
        },
      }
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    props: {
      type_of_request: Number,

      transmital_no: Number,
      category_id: Number,
      branch_id: Number,
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('inventory', ['list_of_stocks', 'register_inventory']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      initialize_data() {
        this.car_brand = ''
        this.list_of_stocks({
          inventory_particulars_id: this.type_of_request,
        })
          .then(response => {
            this.car_brand_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      async save_request() {
        this.saving_data = true
        this.alert = false

        if (this.$refs.form2.validate() && this.category_id > 0 && this.branch_id > 0) {
          const data = new FormData()
          data.append('inventory_particulars_id', this.type_of_request);

          data.append('transmital_no', this.transmital_no);
          data.append('branch_id', this.branch_id);
          data.append('category_id', this.category_id);

          data.append('land_title_no', this.land_title_no.toUpperCase());
          data.append('land_area', this.land_area.toUpperCase());
          data.append('location', this.location.toUpperCase());
          data.append('land_procure', this.land_procure.toUpperCase());
          data.append('land_value', this.land_value);
          data.append('type_of_property', this.type_of_property.toUpperCase());
          data.append('pre_owner', this.pre_owner.toUpperCase());
          data.append('sell_to', this.sell_to.toUpperCase());
          this.register_inventory(data)
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                var color = 'success'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false
            })
            .catch(error => {
              console.log(error)
            })
          await this.initialize_data()
          this.$emit('data', true)
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
    }
  }
</script>
