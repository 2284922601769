<template>
  <div>
    <v-form ref="form2" class="multi-col-validation">
      <v-select
        v-if="type==='Car'"
        v-model="car_brand"
        class="mx-2"
        dense
        outlined
        label="Car"
        :items="car_brand_items"
        item-value="auto_dealer"
        item-text="auto_dealer"
        @change="selected_car"
        :rules="rules.combobox_rule"
      ></v-select>
      <v-select
        v-model="car_unit"
        class="mx-2"
        dense
        outlined
        label="Unit"
        :items="car_unit_items"
        item-value="id"
        item-text="auto_unit"
        @change="selected_car_unit"
        :rules="rules.combobox_rule"
      ></v-select>
      <v-text-field
        readonly
        class="mx-2"
        v-model="auto_mv_file_no"
        label="MV File #"
        dense
        outlined
        :rules="rules.default_max_45_character_and_no_empty_rule"
      ></v-text-field>
      <v-text-field
        readonly
        class="mx-2"
        v-model="auto_plate_no"
        label="Plate #"
        dense
        outlined
        :rules="rules.default_max_45_character_and_no_empty_rule"
      ></v-text-field>
      <v-text-field
        readonly
        class="mx-2"
        v-model="auto_unit_color"
        label="Unit Color"
        dense
        outlined
        :rules="rules.default_max_45_character_and_no_empty_rule"
      ></v-text-field>
      <v-text-field
        readonly
        class="mx-2"
        v-model="auto_chasis_no"
        label="Chasis #"
        dense
        outlined
        :rules="rules.default_max_45_character_and_no_empty_rule"
      ></v-text-field>
      <v-text-field
        readonly
        class="mx-2"
        v-model="auto_engine_no"
        label="Engine #"
        dense
        outlined
        :rules="rules.default_max_45_character_and_no_empty_rule"
      ></v-text-field>
      <v-text-field
        readonly
        class="mx-2"
        v-model="year_model"
        label="Year Model"
        dense
        outlined
        :rules="rules.default_max_45_character_and_no_empty_rule"
        min="0"
        type="number"
      ></v-text-field>
      <v-text-field
        class="mx-2"
        v-model="equipment_date_release"
        label="Date Released"
        dense
        outlined
        :rules="rules.default_max_45_character_and_no_empty_rule"
        type="date"
      ></v-text-field>
      <v-text-field
        readonly
        class="mx-2"
        v-model="auto_unit_value"
        label="Unit Value"
        dense
        outlined
        :rules="rules.default_max_45_character_and_no_empty_rule"
        type="number"
        min="0"
      ></v-text-field>
      <v-col cols="12" v-show="alert">
        <v-alert
          color="warning"
          text
          class="mb-0"
        >
          <div class="d-flex align-start">
            <v-icon color="warning">
              {{ icons.mdiAlertOutline }}
            </v-icon>

            <div class="ms-3">
              <p class="text-base font-weight-medium mb-1">
                {{alert_message}}
              </p>
            </div>
          </div>
        </v-alert>
      </v-col>
      <v-col cols="12" md="12">
        <v-btn
          class="w-full"
          color="primary"
          @click="save_request"
          v-if="!saving_data"
        >
          Save Changes
        </v-btn>
        <v-progress-circular
          :size=50
          :width="5"
          color="primary"
          indeterminate
          v-else
        ></v-progress-circular>
      </v-col>
    </v-form>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mapActions, mapGetters} from "vuex";
  import {mdiCheck, mdiClose} from "@mdi/js";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      saving_data: false,
      alert: false,
      alert_message: '',

      year_model: '0',
      auto_unit_value: '0',
      auto_engine_no: '',
      auto_chasis_no: '',
      auto_unit_color: '',
      auto_plate_no: '',
      auto_mv_file_no: '',
      auto_unit: '',
      auto_dealer: '',
      equipment_date_release: '',

      car_unit: '',
      car_brand: '',
      type: 'Car',

      car_unit_items: [],
      car_brand_items: [],

      equipment_data: {},
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    data() {
      return initialState()
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
        },
      }
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    props: {
      type_of_request: Number,

      transmital_no: Number,
      category_id: Number,
      branch_id: Number,
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('inventory', ['list_of_stocks', 'list_of_stocks_car_details', 'list_of_stocks_equipment_details_color', 'released_stock']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      initialize_data() {
        this.car_brand = ''
        this.list_of_stocks({
          inventory_particulars_id: this.type_of_request,
        })
          .then(response => {
            this.car_brand_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      selected_car_unit(value) {
        this.year_model = '0'
        this.auto_unit_value = '0'
        this.auto_engine_no = ''
        this.auto_chasis_no = ''
        this.auto_unit_color = ''
        this.auto_plate_no = ''
        this.auto_mv_file_no = ''
        this.auto_unit = ''
        this.auto_dealer = ''
        this.equipment_date_release = ''

        var index = this.car_unit_items.map(function (x) {
          return x.id;
        }).indexOf(value)
        if (index != -1) {
          this.equipment_data = this.car_unit_items[index]
          this.year_model = this.equipment_data.year_model+''
          this.auto_unit_value = this.equipment_data.auto_unit_value
          this.auto_engine_no = this.equipment_data.auto_engine_no
          this.auto_chasis_no = this.equipment_data.auto_chasis_no
          this.auto_unit_color = this.equipment_data.auto_unit_color
          this.auto_plate_no = this.equipment_data.auto_plate_no
          this.auto_mv_file_no = this.equipment_data.auto_mv_file_no
          this.auto_unit = this.equipment_data.auto_unit
          this.auto_dealer = this.equipment_data.auto_dealer
        }
      },
      selected_car() {
        this.year_model = '0'
        this.auto_unit_value = '0'
        this.auto_engine_no = ''
        this.auto_chasis_no = ''
        this.auto_unit_color = ''
        this.auto_plate_no = ''
        this.auto_mv_file_no = ''
        this.auto_unit = ''
        this.auto_dealer = ''
        this.car_unit = ''
        this.equipment_date_release = ''

        this.list_of_stocks_car_details({
          inventory_particulars_id: this.type_of_request,
          auto_dealer: this.car_brand,
        })
          .then(response => {
            this.car_unit_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      async save_request() {
        this.saving_data = true
        this.alert = false

        if (this.$refs.form2.validate() && this.category_id > 0 && this.branch_id > 0) {
          const data = new FormData()
          data.append('inventory_id', this.car_unit);
          data.append('inventory_particulars_id', this.type_of_request);
          data.append('equipment_unit_quantity', 0);
          data.append('equipment_date_release', this.equipment_date_release);

          data.append('transmital_no', this.transmital_no);
          data.append('category_id', this.category_id);
          data.append('branch_id', this.branch_id);
          this.released_stock(data)
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                var color = 'success'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false
            })
            .catch(error => {
              console.log(error)
            })
          await this.initialize_data()
          await this.selected_car()
          await this.selected_car_unit()
          this.$emit('data', true)
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
    }
  }
</script>
